const appNamespace = 'deckingsupplies';

export default {
    carousel: {
        resetBreakpoints: `${appNamespace}:carousel:resetBreakpoints`,
    },
    forms: {
        error: `${appNamespace}:forms:error`,
        resetForm: `${appNamespace}:forms:resetForm`,
        resetValidation: `${appNamespace}:forms:resetValidation`,
        submitted: `${appNamespace}:forms:submitted`,
    },
    formValidator: {
        valid: `${appNamespace}:formValidator:valid`,
        invalid: `${appNamespace}:formValidator:invalid`,
        showError: `${appNamespace}:formValidator:showError`,
        removeError: `${appNamespace}:formValidator:removeError`,
    },
    images: {
        lazyLoaded: `${appNamespace}:images:lazyLoaded`,
    },
    prices: {
        taxToggle: `${appNamespace}.prices.taxToggle`,
    }
};
